import { INeuroTemplatedClinicalArchiveDocument } from 'neuro-data-structures';

import { assertCapabilities } from 'Store/index';
import PlatformCapabilities from '../../../config/capabilities';
import { IGroupACLMapping } from '../../../config/definitions/accessControl';
import { PlatformToArchiveMap } from '../../../config/platformSettings';
import { isPlatform, isString, isTPlatformCapability } from '../../../utility/typeGuards';

/**
 * Check which doc types are available in the session.
 * @param groupMapping Groups to capabilities map.
 * @returns Array of available doc types.
 */
export const getAvailableDocTypes = (groupMapping: IGroupACLMapping, platform: string): Array<string> => {
  if (!isPlatform(platform)) return [];
  const platfDocs = PlatformToArchiveMap[platform];
  const assertedDocTypes = platfDocs.map((d) => {
    const capability = `${platform.toUpperCase()}_RECORD_${d}`;
    if (!isTPlatformCapability(capability)) return;
    const pCapability = PlatformCapabilities[capability as keyof typeof PlatformCapabilities];
    return assertCapabilities([pCapability], groupMapping ?? {}) ? d : undefined;
  });
  const pruned: Array<string> = [];
  assertedDocTypes.forEach((d) => {
    if (isString(d)) pruned.push(d);
  });
  return pruned;
};

/**
 * Archive object that has been fetched from api and sorted
 */
export interface IArchiveObject extends INeuroTemplatedClinicalArchiveDocument {
  /** Timestamp for the first revision of the document */
  firstTimestamp?: number;
}

/**
 * Object describing changes to text blocks withing the clinical document
 */
export interface IClinicalTextObject {
  /** sectionIndex, number, "Index of the clinical archive document section that should be updated" */
  sectionIndex: number;
  /** contentIndex, number, "Index of the section textContent block that should be updated" */
  contentIndex: number;
  /** text, string, "Optional new text value of the section text content block, overwrites completely. May be set to null to nullify the content text field. If left out then the text value is not updated." */
  text?: string | null;
  /** exclude, boolean, "Optional new section text content block exclusion state. If left out then excluded state if not updated" */
  exclude?: boolean;
  /** dataStartTimeStamp, number, "Optional override of archive document dataStartTimestamp that this blocks textContent compiler should use instead of the archive objects main dataStartTimestamp." */
  dataStartTimestamp?: number | null;
  /** dataEndTimeStamp, number, "Optional override of archive document dataEndTimeStamp that this blocks textContent compiler should use instead of the archive objects main dataEndTimeStamp." */
  dataEndTimestamp?: number | null;
}
