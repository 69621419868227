import { useSelector } from 'react-redux';
import * as React from 'react';
import L from 'Components/Localized';
import type { RootState } from 'Store/index.js';

const style: React.CSSProperties = {
  height: 'calc(100vh - 16px)', // 16 = body margin * 2
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

/**
 * A view indicating that the current browsing context session (e.g. tab,
 * window, frame, iframe etc...) has become invalid.
 */
const InvalidSessionView = (): React.ReactNode => {
  const { init_at_client_time, invalidated_at_client_time } = useSelector((state: RootState) => state.session.status);

  const start: string = new Date(init_at_client_time).toLocaleTimeString();
  let stop: string;
  if (typeof invalidated_at_client_time === 'number') {
    stop = ' - ' + new Date(invalidated_at_client_time).toLocaleTimeString();
  } else {
    stop = '';
  }

  const session_lifespan: string = start + stop;

  return (
    <div style={style}>
      <span>{session_lifespan}</span>
      <span>
        <L>{'general.session_state.invalidated'}</L>
      </span>
      <span>
        <L>{'general.session_state.recover_instruction'}</L>
      </span>
    </div>
  );
};

export default InvalidSessionView;
