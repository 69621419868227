import * as React from 'react';
import { useIntl } from 'react-intl';

import { SurveyDialog } from '.';
import NotificationBar from '../NotificationBar';
import Icon from '../Icon';
import { Container, Item } from 'Components/Grid';
import { useAppDispatch, useAppSelector } from 'Store/index';
import { actions } from 'Store/session';

const NotificationBarHandler = () => {
  const survey = useAppSelector((state) => state.session.survey);

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const [surveyOpen, setSurveyOpen] = React.useState<string | null>(null);

  const dispatch = useAppDispatch();
  const rejectSurvey = () => survey && dispatch(actions.surveyDismissAction(survey));

  const dismissDate = survey?.dismissed_by_user_at && new Date(survey?.dismissed_by_user_at);
  const currentDate = new Date();
  // Set new date when to show survey to 6 months in the future
  const futureDate = dismissDate && new Date(dismissDate.setMonth(dismissDate.getMonth() + 6));

  const dismissed = futureDate ? futureDate > currentDate : null;

  return survey && !dismissed ? (
    <>
      <div key={survey.survey_id}>
        <NotificationBar
          content={
            <Container alignItems={'center'} justifyContent={'center'}>
              <Item style={{ margin: '0 1rem' }}>
                <Icon icon="error" color="appBlueDark" />
              </Item>
              <Item
                className="ellipsis"
                style={{
                  fontWeight: 600,
                  fontSize: '2rem',
                  lineHeight: '2.4rem',
                  maxWidth: '60rem',
                }}
              >
                {survey.title || fm('header.survey')}
              </Item>
              <Item
                className="ellipsis"
                style={{
                  fontWeight: 400,
                  fontSize: '1.6rem',
                  lineHeight: '1.8rem',
                  marginLeft: '2rem',
                  maxWidth: '40rem',
                }}
              >
                {survey.subtitle || fm('header.surveySubtitle')}
              </Item>
            </Container>
          }
          buttons={[
            {
              text: 'header.answerSurvey',
              width: 8,
              height: 3,
              fontSize: 14,
              onClick: () => setSurveyOpen(survey.survey_id),
              filled: true,
              colorScheme: 'dark',
            },
            {
              text: 'header.dontAnswerSurvey',
              width: 6,
              height: 3,
              fontSize: 14,
              onClick: () => rejectSurvey(),
              border: false,
              colorScheme: 'dark',
            },
          ]}
        />
      </div>

      {survey && <SurveyDialog open={!!surveyOpen} survey={survey} setOpenDialog={() => () => setSurveyOpen(null)} />}
    </>
  ) : null;
};

export default NotificationBarHandler;
