/**
 * Paper component for new UI components
 */

import { Paper as PaperMui, PaperProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { styled } from '@mui/system';
import * as React from 'react';

import { Container, Item } from 'Components/Grid';

const Header = styled(Container, { shouldForwardProp: (prop) => prop !== 'padding' })(
  ({ theme, padding }: { theme?: Theme; padding: number }) => ({
    backgroundColor: theme?.palette.primary.main,
    color: theme?.palette.common.white,
    padding: `1rem ${padding}rem`,
    height: '6.5rem',
    boxSizing: 'border-box' as const,
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
  }),
);

const Body = styled('div', { shouldForwardProp: (prop) => prop !== 'padding' })(({ padding }: { padding: number }) => ({
  padding: `${padding}rem`,
}));

export const HeaderComponent = ({ padding, header }: { padding: number; header: JSX.Element }) => (
  <Header padding={padding} alignItems="center">
    <Item>
      <h2 style={{ margin: 0 }}>{header}</h2>
    </Item>
  </Header>
);

const Paper = ({ header, padding = 3.5, square = false, elevation = 2, children, ...props }: IPaper) => {
  return (
    <PaperMui square={square} elevation={elevation} {...props}>
      {header && <HeaderComponent padding={padding} header={header} />}
      <Body padding={padding}>{children}</Body>
    </PaperMui>
  );
};

interface IPaper extends PaperProps {
  header?: JSX.Element;
  /** Paper padding in rems */
  padding?: number;
  square?: boolean;
  elevation?: number;

  children?: JSX.Element | null | false | React.ReactNode | Array<JSX.Element | null | false>;
}

export default Paper;
