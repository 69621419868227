import { useTheme } from '@mui/material';
import { Info } from '@mui/icons-material';
import { styled } from '@mui/system';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch as useDispatch, useAppSelector as useSelector, assertCapabilities } from 'Store/index';
import { useNavigate } from 'react-router-dom';

import InputHandler from '../../../../components/InputHandler';
import { Container, Item } from '../../../../components/Grid';
import { ICapabilityContextProps, withCapabilities } from '../../../../containers/CapabilityHandler';
import Paper from 'Components/Paper';
import Divider from 'Components/Divider';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import CircularProgressLocal from 'Components/CircularProgress';

import { actions as actionsSession } from '../../../../store/session';

import { fetchWithOptions } from '../../../../utility/fetch';
import { parseJWTFromCookie } from '../../../../utility/jwtAuthTools';
import { makeLog } from '../../../../utility/logger';
import PlatformCapabilities from '../../../../config/capabilities';

import Appointments from './Appointments';
import Columns from './Columns';
import { localStorageSave } from 'Utility/localStorage';
import { sortPlatformsByLocale } from './utils';

const Section = styled('div')({
  paddingLeft: '3.5rem',
});

const optionFormatter = (s: string | number): JSX.Element => <FormattedMessage id={`general.opts.${s}`} />;
const optionFormatterPlatforms = (s: string | number): JSX.Element => <FormattedMessage id={`header.platforms.${s}`} />;

const SectionHeader = ({ text }: { text: string }): JSX.Element => {
  const theme = useTheme();
  return (
    <>
      <Container style={{ marginBottom: '0.5rem' }} alignItems="center">
        <Item style={{ width: '3.5rem' }}>
          <Info color="primary" style={{ display: 'block', fontSize: '2.6rem' }} />
        </Item>
        <Item>
          <div className="subtitle" style={{ color: theme.palette.grey[700] }}>
            <FormattedMessage id={text} />
          </div>
        </Item>
      </Container>
    </>
  );
};

const SmallText = ({ text }: { text: string }): JSX.Element => {
  const theme = useTheme();
  return (
    <span className="small" style={{ color: theme.palette.grey[500] }}>
      <FormattedMessage id={text} />
    </span>
  );
};

const VisitReason = ({ patient, capabilityGroups = {} }: IOwnProps): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });
  const [reason, setReason] = React.useState('');
  const [selectedPlatform, setSelectedPlatform] = React.useState<Platform | null>(null);
  const [selectedOtherPlatform, setSelectedOtherPlatform] = React.useState<Platform | null>(null);
  const [selectedAppointment, setSelectedAppointment] = React.useState<string | null>(null);
  const [loadingPreferredPlatforms, setLoadingPreferredPlatforms] = React.useState<boolean>(true);
  const [preferredPlatforms, setPreferredPlatforms] = React.useState<Platform[] | null>(null);

  const allPlatforms = useSelector((s: IState) => s.session.data?.platforms) as Platform[] | undefined;
  const platformsNonPreferred = sortPlatformsByLocale(
    allPlatforms?.filter((p) => !preferredPlatforms?.includes(p)),
    fm,
  );

  const userid = useSelector((state: IState) => state.session.data?.useruuid);
  const orgid = useSelector((state: IState) => state.session.data?.orgid);

  const dispatch = useDispatch();
  React.useEffect(() => {
    const lifeCareSyncCapability = assertCapabilities([PlatformCapabilities.LIFECARE_DIAGNOSIS_SYNC], capabilityGroups);
    actionsSession
      .preferredPlatforms(dispatch, lifeCareSyncCapability)
      .then((platforms) => {
        const filteredAvailable = platforms && platforms.filter((p) => (allPlatforms || []).includes(p));
        setPreferredPlatforms(sortPlatformsByLocale(filteredAvailable || undefined, fm) || null);
        setLoadingPreferredPlatforms(false);
      })
      .catch(() => {
        setPreferredPlatforms(null);
        setLoadingPreferredPlatforms(false);
      });
  }, []);

  // if there is exactly one preferred platform, select it automatically
  React.useEffect(() => {
    if (Array.isArray(preferredPlatforms) && preferredPlatforms.length === 1) {
      setSelectedPlatform(preferredPlatforms[0]);
    } else {
      setSelectedPlatform(null);
    }
  }, [preferredPlatforms]);

  const changePlatform = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    setSelectedPlatform(value as Platform);
    setSelectedOtherPlatform(null);
  };
  const changeOtherPlatform = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    setSelectedOtherPlatform(value as Platform);
    setSelectedPlatform(null);
  };
  const changeReason = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    setReason(value as string);
  };
  const changeAppointment = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    setSelectedAppointment(value as string);
  };

  const history = useNavigate();

  const updateSession = (): void => {
    dispatch(actionsSession.updateSession());
  };
  const updatePlatforms = (): void => {
    if (selectedPlatform || selectedOtherPlatform) {
      localStorageSave('platform', (selectedPlatform || selectedOtherPlatform) as Platform);
      dispatch(actionsSession.setPlatform((selectedPlatform || selectedOtherPlatform) as Platform));
    }
  };

  const onClickNext = (): void => {
    if ((reason && reason !== '') || selectedAppointment) {
      fetchWithOptions(
        `/api/visit/create`,
        { neurojwt: parseJWTFromCookie() },
        {
          method: 'POST',
          body: JSON.stringify({
            visitDate: Date.now(),
            visitReason: reason || null,
            visitExternalId: selectedAppointment || null,
          }),
        },
      )
        .then(
          (res: Response) => {
            if (res.status === 200) {
              return res.text().then((text: string) =>
                fetchWithOptions(
                  `/auth/modify`,
                  { neurojwt: parseJWTFromCookie() },
                  { method: 'PATCH', body: text },
                ).then((jwtRes: Response) => {
                  if (jwtRes.status === 200) {
                    // Record entering patient data when visit has been created
                    makeLog(
                      'Info',
                      {
                        name: 'User entered patient data',
                        message: `User ${userid} from org ${orgid} entered patient ${
                          patient.patientid
                        } data using platform ${selectedPlatform || selectedOtherPlatform}`,
                      },
                      {
                        userid,
                        orgid,
                        patientid: patient.patientid,
                        platform: selectedPlatform || selectedOtherPlatform,
                      },
                    );

                    updateSession();
                    updatePlatforms();
                    history('/');
                  } else throw { status: res.status, fullResponse: res };
                }),
              );
            } else {
              throw { status: res.status, fullResponse: res };
            }
          },
          (error: Error) => {
            throw error;
          },
        )
        .catch((err: Error) => makeLog('Error', err));
    }
  };

  const unselectPatient = () => {
    actionsSession.unselectPatientAndVisit(dispatch);
  };

  const submitDisabled = (!selectedPlatform && !selectedOtherPlatform) || (!reason && !selectedAppointment);
  const submitDisabledReason = 'general.visitSubmitDisabledNoReason';

  const appointmentsCapability = assertCapabilities([PlatformCapabilities.APPOINTMENTS], capabilityGroups);
  return (
    <Paper
      header={
        <>
          <span>{patient?.patientlastnames + ' ' + patient?.patientfirstnames}</span>
          <span style={{ marginLeft: '1rem', fontWeight: 400, fontSize: '1.6rem' }}>{patient?.patientssn}</span>
        </>
      }
      style={{
        width: '70rem',
      }}
    >
      <SectionHeader text={'general.choosePlatform'} />
      <Section>
        <Columns
          leftItem={
            <>
              <div style={{ marginBottom: '0.5rem' }}>
                <SmallText text={'general.autoChosenPlatforms'} />
              </div>
              {loadingPreferredPlatforms ? (
                <Container>
                  <Item style={{ marginRight: '1rem', display: 'flex', alignItems: 'center' }}>
                    <CircularProgressLocal size={20} />
                  </Item>
                  <Item style={{ fontSize: '1.6rem' }}>
                    <FormattedMessage id="general.loadingChosenPlatforms" />
                  </Item>
                </Container>
              ) : preferredPlatforms && preferredPlatforms.length > 0 ? (
                <InputHandler
                  type="Radio"
                  name="selectedPlatform"
                  editing={true}
                  options={preferredPlatforms || []}
                  optionFormatter={optionFormatterPlatforms}
                  formData={{
                    onChange: changePlatform,
                    document: { selectedPlatform },
                  }}
                  compact
                />
              ) : (
                <div style={{ fontSize: '1.6rem' }}>
                  <FormattedMessage id="general.noChosenPlatforms" />
                </div>
              )}
            </>
          }
          rightItem={
            <>
              <SmallText text={'general.otherPlatforms'} />
              <InputHandler
                type="Select"
                name="selectedOtherPlatform"
                editing={true}
                options={platformsNonPreferred || []}
                optionFormatter={optionFormatterPlatforms}
                formData={{
                  onChange: changeOtherPlatform,
                  document: { selectedOtherPlatform },
                }}
                placeholder="general.otherPlatformsPlaceholder"
              />
            </>
          }
        />
      </Section>
      <Divider />
      <SectionHeader text={'general.visitReason'} />
      <Section>
        <Columns
          leftItem={
            <InputHandler
              type="Radio"
              name="visitReason"
              editing={true}
              options={[
                'patientVisit',
                'patientPhone',
                'doctorOtherHospital',
                'doctorSameHospital',
                'retrospectiveDataTransfer',
                'other',
              ]}
              optionFormatter={optionFormatter}
              formData={{
                onChange: changeReason,
                document: { visitReason: reason },
              }}
              compact
            />
          }
          rightItem={<></>}
        />
      </Section>
      {appointmentsCapability && (
        <>
          <Divider />
          <SectionHeader text="appointments.title" />
          <Section>
            <Appointments selectedAppointment={selectedAppointment} changeAppointment={changeAppointment} />
          </Section>
        </>
      )}

      <Divider />
      <Container>
        <Item xs={6}>
          <ActionButtonRounded width={10} height={4} fontSize={16} text="general.cancel" onClick={unselectPatient} />
        </Item>
        <Item xs={6}>
          <Container justifyContent="flex-end">
            <Item>
              <ActionButtonRounded
                width={20}
                height={4}
                fontSize={16}
                text="general.toData"
                onClick={onClickNext}
                disabled={submitDisabled}
                disabledTooltip={submitDisabledReason ? <FormattedMessage id={submitDisabledReason} /> : undefined}
                filled
              />
            </Item>
          </Container>
        </Item>
      </Container>
    </Paper>
  );
};

interface IOwnProps extends ICapabilityContextProps {
  patient: { patientid: string; patientfirstnames?: string; patientlastnames?: string; patientssn?: string };
}

export default withCapabilities(VisitReason);
