import * as React from 'react';

import Icon from 'Components/_NewElements/Icon';
import { Container, Item } from 'Components/Grid';

export const StarsInput = ({ name, starCount = 5, value, onChange, starStyle }: IStarsInput) => {
  return (
    <Container justifyContent={'space-between'}>
      {[...Array(starCount)].map((_, i) => (
        <Item style={starStyle || { margin: '0 1rem' }} key={name + i} onClick={() => onChange(i + 1)}>
          {value >= i + 1 ? <Icon icon="star" size={3} button /> : <Icon icon="starEmpty" size={3} button />}
        </Item>
      ))}
    </Container>
  );
};

interface IStarsInput {
  name: string;
  starCount?: number;
  value: number;
  onChange: (value: number) => void;
  starStyle?: React.CSSProperties;
}
