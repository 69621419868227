import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { IReportEmbedConfiguration } from 'powerbi-models';
import * as React from 'react';

import { useAppDispatch, useAppSelector } from 'Store/index';
import { actions } from 'Store/session';
import { useIntl } from 'react-intl';
import { omit } from 'Utility/ramdaReplacement';

const defaultSettings: (locale: string) => IReportEmbedConfiguration['settings'] = (locale) => ({
  localeSettings: {
    language: locale === 'fi' ? 'fi-FI' : 'en-US',
    formatLocale: 'fi',
  },
  panes: {
    filters: {
      expanded: false,
      visible: true,
    },
    pageNavigation: {
      visible: true,
    },
  },
});

const PowerBIStatistics = ({ platform }: IPowerBIStatistics) => {
  const tokenData = useAppSelector((s) =>
    s.session.statisticsTokenData && platform in s.session.statisticsTokenData
      ? s.session.statisticsTokenData[platform]
      : undefined,
  );

  const orgSettings = useAppSelector((s) => {
    if (platform && s.settings.orgSettings.settings?.powerbi[platform]) {
      return s.settings.orgSettings.settings?.powerbi[platform];
    } else return {};
  });

  const tokenExpired = tokenData?.embedTokenExpiration
    ? Date.parse(tokenData.embedTokenExpiration) <= new Date().valueOf()
    : true;

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (!tokenData || tokenExpired) {
      actions.getStatisticsTokenData(platform)(dispatch);
    }
  }, []);

  const { locale } = useIntl();
  const reportConfig: IReportEmbedConfiguration | null = tokenData
    ? {
        type: 'report',
        tokenType: models.TokenType.Embed,
        accessToken: tokenData?.embedToken,
        embedUrl: tokenData?.embedUrl,
        settings: { ...defaultSettings(locale), ...omit(['reportId'], orgSettings) },
      }
    : null;

  return reportConfig ? <PowerBIEmbed embedConfig={reportConfig} cssClassName="power-bi-container" /> : <></>;
};

interface IPowerBIStatistics {
  platform: Platform;
}

export default PowerBIStatistics;
