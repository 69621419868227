import { Container, Item } from 'Components/Grid';
import * as React from 'react';
import colors from '../../config/theme/colors';
import { styled } from '@mui/system';
import { fm } from 'Components/FormatMessage';

const StyledWrapper = styled('div')({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'row',
});

export const StyledContentDivider = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ open }: { open?: boolean }) => ({
  width: '0.7rem',
  minWidth: '0.7rem',
  height: 'auto',
  borderRadius: '0.2rem',
  backgroundColor: `${colors.brandBlue.default}`,
  opacity: open ? 0.15 : 0.5,
  boxSizing: 'border-box' as const,
}));

const StyledContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  boxSizing: 'border-box',
});

const StyledArrow = styled('div')({
  width: '1.5rem',
  minWidth: '1.5rem',
  height: '2rem',
  display: 'flex',
  justifyContent: 'right',
  alignItems: 'center',
});

const StyledTitle = styled('div')({
  width: 'min-content',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.8rem',
  color: colors.brandBlue.default,
  fontWeight: 600,
  whiteSpace: 'nowrap',
  userSelect: 'none',
  boxSizing: 'border-box',
  padding: '0 0.5rem',
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});

const StyledClear = styled(StyledTitle)({
  color: colors.primary,
  fontWeight: 400,
  fontSize: '1.4rem',
});

const StyledContainer = styled(Container)({
  padding: '0 0 0.5rem 0',
  position: 'relative',
  '&:after': {
    content: '""',
    width: '97.5%',
    background: colors.tertiary,
    position: 'absolute',
    bottom: 2,
    left: '1.5rem',
    height: '1px',
  },
});

const CollapsibleRow = ({
  title,
  content,
  collapsedContent = <></>,
  onClear,
  collapseOnClear,
  collapsed,
}: ICollapsibleRow): JSX.Element => {
  const [open, setOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (collapsed === true) {
      setOpen(false);
    } else if (collapsed === false) {
      setOpen(true);
    }
  }, [collapsed]);

  return (
    <StyledContainer>
      <Item xs={12}>
        <StyledWrapper>
          <StyledContentDivider open={open} />
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', boxSizing: 'border-box' }}>
            <StyledContentWrapper>
              <div style={{ width: '1.5rem', display: 'flex' }}>
                <StyledArrow>
                  <svg
                    transform={`rotate(${open ? 0 : -90})`}
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 5.71411L0.669872 0.267852L9.33013 0.267851L5 5.71411Z"
                      fill={colors.brandBlue.default}
                    />
                  </svg>
                </StyledArrow>
              </div>
              <div style={{ width: '100%', display: 'grid', alignItems: 'baseline' }}>
                <StyledTitle onClick={() => setOpen(!open)} style={{ gridColumn: '1 / 2' }}>
                  {typeof title === 'string' ? fm(title) : title}
                </StyledTitle>
                {onClear && (
                  <StyledClear
                    onClick={() => {
                      onClear();
                      collapseOnClear && setOpen(false);
                    }}
                    style={{ gridColumn: '2 / 2', justifySelf: 'flex-end' }}
                  >
                    {fm('general.clear')}
                  </StyledClear>
                )}
              </div>
            </StyledContentWrapper>
            <div style={{ display: 'flex', padding: '0.5rem 0 2rem 2rem', color: colors.tertiaryText }}>
              {open ? <div style={{ width: '100%', paddingTop: '1rem' }}>{content}</div> : collapsedContent}
            </div>
          </div>
        </StyledWrapper>
      </Item>
    </StyledContainer>
  );
};

interface ICollapsibleRow {
  /** Row title */
  title: string;
  /** Content displayed when row is open */
  content: string | JSX.Element;
  /** Content displayed when row is in collapsed state */
  collapsedContent?: string | JSX.Element;
  /** If defined, display clear button with given functionality */
  onClear?: () => void;
  /** Whether to collapse when cleared  */
  collapseOnClear?: boolean;
  /** Allow collapsing the component from parent component */
  collapsed?: boolean;
}

export default CollapsibleRow;
