import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import * as React from 'react';
import { Settings } from 'luxon';
import { Outlet } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import { GlobalStyles } from '@mui/system';

import Header from '../../components/Header';
import { actions as documentActions } from '../../store/documents/actions';
import { actions as sessionActions } from '../../store/session';
import { actions as patientActions } from '../../store/patient/actions';
import { actions as myAppActions } from '../../store/myapp/actions';

import globals from '../../config/theme/globals';
import theme from '../../config/theme/muiTheme';
import SessionLoader from '../SessionLoader';
import PlatformLoader from '../PlatformLoader';

import ErrorContainer from '../ErrorContainer';
import PlatformCapabilities from '../../config/capabilities';
import { assertCapabilities } from '../../store/index';
import { ICapabilityContextProps, withCapabilities } from '../CapabilityHandler';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import CenteredLoadingIndicator from 'Components/CenteredLoadingIndicator';
import { NamePlate } from 'Components/NamePlate';
import { LocalizerCtx } from 'Containers/Localizer';
import { checkMyServCapability } from 'Routes/MyService/util';
import NotificationBarHandler from 'Components/_NewElements/Surveys/NotificationBarHandler';

const DataLoader = withCapabilities(({ session, children, capabilityGroups = undefined }: IDataLoader): JSX.Element => {
  const dispatch = useAppDispatch();
  const documents = useAppSelector((state: RootState) => state.documents.documents);
  const grants = useAppSelector((state: RootState) => state.patient.grants);

  const fetchDocData = React.useCallback(
    (firstFetch: boolean) =>
      documentActions.fetchDocumentData(
        firstFetch,
        assertCapabilities([PlatformCapabilities.MIRANDA_MEDICATION_HIDE_OVERLAPPING], capabilityGroups),
      )(dispatch),

    [dispatch, capabilityGroups],
  );
  const fetchPatientData = React.useCallback(() => patientActions.loadPatientData()(dispatch), [dispatch]);
  const fetchPatientGrants = React.useCallback(() => patientActions.loadGrantsData()(dispatch), [dispatch]);
  const fetchPatientDelegates = React.useCallback(() => patientActions.loadDelegates()(dispatch), [dispatch]);
  const fetchPatientContactInfo = React.useCallback(() => patientActions.loadContactInfo()(dispatch), [dispatch]);
  const fetchMyAppData = React.useCallback(() => myAppActions.loadMyAppData(dispatch), [dispatch]);
  const visitingPatient = !!(session.data?.patientid && session.data.visitid);

  // Fetch documents on first mount if there are none in store yet, needs capabilities
  React.useEffect(() => {
    if (capabilityGroups && !documents && visitingPatient) {
      fetchDocData(true);
    }
  }, [documents, visitingPatient, capabilityGroups]);

  React.useEffect(() => {
    if (capabilityGroups && visitingPatient) sessionActions.doSyncFetchesAndGetDocuments(dispatch, capabilityGroups);
  }, [capabilityGroups, visitingPatient]);

  // Fetch patient data from patient service
  React.useEffect(() => {
    if (visitingPatient) {
      fetchPatientData();
      fetchPatientGrants();
      fetchPatientDelegates();
      fetchPatientContactInfo();
    }
  }, [visitingPatient]);

  // Fetch data from MyApp
  React.useEffect(() => {
    if (
      visitingPatient &&
      session.platforms?.selected &&
      capabilityGroups &&
      checkMyServCapability(session.platforms.selected, capabilityGroups)
    ) {
      fetchMyAppData();
    }
  }, [visitingPatient, session.platforms?.selected, capabilityGroups]);

  const medIntegrationDocs = Array.isArray(documents)
    ? documents.filter(
        (d) => d.documentType === 'medication' && d._lockedFor && Array.isArray(d.commits) && d.commits.length > 1,
      )
    : undefined;

  // Handle documents from miranda integration that have changed start dates
  React.useEffect(() => {
    if (Array.isArray(medIntegrationDocs) && medIntegrationDocs.length > 0) {
      const medIntegrationDocsWithChangedStartDates = medIntegrationDocs.filter((d) => {
        const latestCommit = d.commits[d.commits.length - 1].data;
        const previousCommit = d.commits[d.commits.length - 2].data;
        // If latest/previous commit neither have startDate, startDate has not changed
        if (!Array.isArray(latestCommit?.startDate) && !Array.isArray(previousCommit?.startDate)) return false;
        // If latest/previous commit has startDate but the other doesn't, startDate has changed
        if (
          (Array.isArray(latestCommit?.startDate) && !Array.isArray(previousCommit?.startDate)) ||
          (!Array.isArray(latestCommit?.startDate) && Array.isArray(previousCommit.startDate))
        )
          return true;
        // If latest/previous commit startDates exist but are different, startDate has changed
        return !latestCommit.startDate.every(
          (num: number | null, ind: number) => num === previousCommit.startDate[ind],
        );
      });
      if (medIntegrationDocsWithChangedStartDates.length === 0) return;
      medIntegrationDocsWithChangedStartDates.forEach((d) => {
        const data = d.commits[d.commits.length - 1].data;
        if (!Array.isArray(data.usageStartDate)) return;
        // Nullify usageStartDate from miranda integration docs that have changed start dates

        documentActions.modifyField(
          { name: 'medication', id: d.documentId },
          data,
          { usageStartDate: null },
          true,
        )(dispatch);
      });
    }
  }, [Array.isArray(medIntegrationDocs) && medIntegrationDocs?.length]);

  return (
    <>
      {visitingPatient && !documents && !grants ? (
        <CenteredLoadingIndicator descriptionText={<FormattedMessage id="general.loadingData" />} />
      ) : (
        children
      )}
    </>
  );
});

const Layout = (): JSX.Element => {
  const { locale } = React.useContext(LocalizerCtx);

  return (
    <ErrorContainer>
      <GlobalStyles styles={globals} />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterLuxon as any} adapterLocale={locale || Settings.defaultLocale}>
            <SessionLoader>
              {(session): JSX.Element => (
                <React.Fragment>
                  <ErrorContainer>
                    <NotificationBarHandler />
                  </ErrorContainer>

                  <ErrorContainer>
                    <Header session={session} />
                  </ErrorContainer>
                  <DataLoader session={session}>
                    <PlatformLoader>
                      <Outlet />
                    </PlatformLoader>
                  </DataLoader>
                  <ErrorContainer>
                    <NamePlate />
                  </ErrorContainer>
                </React.Fragment>
              )}
            </SessionLoader>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ErrorContainer>
  );
};

interface IDataLoader extends ICapabilityContextProps {
  session: ISessionStore;
  children: JSX.Element | null;
}

export default Layout;
