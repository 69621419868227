import * as React from 'react';
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { useAppSelector as useSelector } from 'Store/index';
import { equals, pick } from 'ramda';

import { Dashboard, documentRoutes } from '../../routes';
import Search from '../../routes/_Patient/SearchAndCreation';

import Layout from '../Layout/index';

import { getEnabledDocuments, getNavigableRoutes } from '../../utility/randomUtil';
import { getJWTData } from '../../utility/jwtAuthTools';
import PatientRecords from 'Routes/_PatientRecords';

type FocusChangeListener = () => void;
const compareKeys: Array<keyof IJWTBody> = ['patientid', 'visitid', 'useruuid']; // Which keys to use for detecting changed JWT

const createFocusListener = (): FocusChangeListener => {
  const jwt = getJWTData();
  if (!jwt) return () => null;
  const oldValue = pick(compareKeys, jwt);
  return (): void => {
    const newValue = pick(compareKeys, jwt);
    if (!equals(oldValue, newValue)) location.replace('/');
  };
};

const MainRouter = (): JSX.Element => {
  const session = useSelector((s: { session: ISessionStore }) => s.session);
  const sections = getEnabledDocuments(session).map((d: { name: string }) => d.name);
  const navigableSections = getNavigableRoutes(session).map((d: { name: string }) => d.name);

  React.useEffect(() => {
    const listener = createFocusListener();
    window.addEventListener('focus', listener);
    return (): void => {
      window.removeEventListener('focus', listener);
    };
  });

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Layout />}>
        <Route key="main" path="/" element={<Dashboard sections={sections} />} />
        <Route key="search" path="/search" element={<Search prevPatient={true} />} />
        <Route key="patientRecords" path="/patientRecords" element={<PatientRecords />} />
        {documentRoutes(navigableSections).map((route) => (
          <Route key={route.path} {...route} />
        ))}
        <Route key="matchOtherPaths" path="*" element={<Dashboard sections={sections} />} />
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
};

export default MainRouter;
