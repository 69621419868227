import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/system';

import { Container, Item } from 'Components/Grid';
import TextArea from 'Components/InputHandler/components/TextArea';

import colors from '../../../config/theme/colors';

import { IArchiveObject, IClinicalTextObject } from './helpers';
import { INeuroArchiveTemplate } from 'neuro-data-structures';
import InfoPopper from 'Components/InfoPopper';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import PartialDate from 'Components/InputHandler/components/PartialDate';
import {
  calculatePartialDateDifferenceInMonths,
  isPartialDate,
  nowPartialDate,
  partialDateFromDate,
  partialDateToValue,
} from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import {
  getTextContentDefaultTimeframeByType,
  getTextContentTimeframeSelectorLocByType,
  textContentTypesWithTimeframe,
} from '../config';

const Section = styled('div')({
  marginBottom: '2rem',
});

const Title = styled('div')({
  fontSize: '1.6rem',
  fontWeight: 600,
});

const TextInputArea = styled('div')({
  borderRadius: '0.4rem',
  padding: '0.3rem 1rem',
  margin: '2rem 0rem 2rem 2rem',
  width: '70%',
  border: `0.1rem solid ${colors.lightGray}`,
});

const Paragraph = styled('div')({
  fontSize: '1.6rem',
  paddingLeft: '2rem',
  marginBottom: '2rem',
  whiteSpace: 'pre-wrap',
});

const InlineDiv = styled('div')({
  display: 'inline-flex',
});

const Frame = styled('div')({
  borderRadius: '0.4rem',
  border: `0.1rem solid ${colors.lightGray}`,
});

const TimeframeTitle = styled('div')({
  fontSize: '1.4rem',
  color: colors.tertiaryText,
});

const formatTimestampToDate = (timestamp: number): string => {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

const ConditionalAreaWithTimeframe = ({
  condition,
  editing,
  timeframe,
  setTimeframeNew,
  type,
  currentTextContent,
  children,
}: {
  condition: boolean;
  editing: boolean;
  timeframe: { dataStartTimestamp: number; dataEndTimestamp: number } | undefined;
  type: string | undefined;
  currentTextContent: IClinicalTextObject;
  setTimeframeNew: IClinicalDocElements['setTimeframeNew'];
  children: JSX.Element;
}): JSX.Element => {
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [editingTimeframe, setEditingTimeframe] = React.useState<{
    dataStartTimestamp: number;
    dataEndTimestamp: number;
  }>(timeframe ?? { dataStartTimestamp: 0, dataEndTimestamp: 0 });

  if (!timeframe || !type) return children;

  const formattedStartDate = formatTimestampToDate(timeframe.dataStartTimestamp);
  const formattedEndDate = formatTimestampToDate(timeframe.dataEndTimestamp);

  const partialStartDate = partialDateFromDate(new Date(editingTimeframe.dataStartTimestamp));
  const partialEndDate = partialDateFromDate(new Date(editingTimeframe.dataEndTimestamp));

  const onChangeTimeframe = (e: TOnChangeValues): void => {
    const name = Object.keys(e)[0];
    const value = e[name];
    if (value && isPartialDate(value)) {
      setEditingTimeframe({ ...editingTimeframe, [name]: partialDateToValue(value) });
    }
  };

  const updateTimeframe = () => {
    setTimeframeNew?.({ ...currentTextContent, ...editingTimeframe });
  };

  if (condition) {
    return (
      <Frame style={{ margin: '2rem 0 2rem 0' }}>
        <div
          style={{
            padding: '0.5rem 2rem 0.5rem 2rem',
            borderBottom: `0.1rem solid ${colors.lightGray}`,
            marginBottom: '1.5rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <span>{fm(getTextContentTimeframeSelectorLocByType(type)[isEditing ? 'editing' : 'default'])}</span>
              &nbsp;&nbsp;
              {!isEditing && (
                <span style={{ fontWeight: 600 }}>{`${formattedStartDate}\u00A0–\u00A0${formattedEndDate}`}</span>
              )}
            </div>
            <div style={{ visibility: isEditing ? 'hidden' : undefined }}>
              {editing && (
                <ActionButtonRounded
                  text="general.edit"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                  width={10}
                  height={3}
                  fontSize={14}
                />
              )}
            </div>
          </div>
          {isEditing && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '1rem' }}>
                <InlineDiv>
                  <div>
                    <TimeframeTitle>{fm('patientRecords.starting')}</TimeframeTitle>
                    <PartialDate
                      type="PartialDate"
                      name="dataStartTimestamp"
                      value={partialStartDate}
                      onChange={onChangeTimeframe}
                      editing={true}
                      isNotCancellable
                      // TODO: Configure by type
                      dateHook={{
                        dateHookCeiling: partialEndDate,
                      }}
                      disableDateSelection
                      dateFormat="dd.mm.yyyy"
                    />
                  </div>
                </InlineDiv>
                <span style={{ paddingTop: '2rem' }}>{'\u00A0–\u00A0'}</span>
                <InlineDiv style={{ paddingLeft: '1rem' }}>
                  <div>
                    <TimeframeTitle>{fm('patientRecords.ending')}</TimeframeTitle>
                    <PartialDate
                      type="PartialDate"
                      name="dataEndTimestamp"
                      value={partialEndDate}
                      onChange={onChangeTimeframe}
                      editing={true}
                      isNotCancellable
                      // TODO: Configure by type
                      dateHook={{
                        dateHookFloor: partialStartDate,
                        dateHookCeiling: nowPartialDate(),
                      }}
                      disableDateSelection
                      dateFormat="dd.mm.yyyy"
                    />
                  </div>
                </InlineDiv>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '1.2rem' }}>
                <ActionButtonRounded
                  text="general.cancel"
                  onClick={() => {
                    setEditingTimeframe(timeframe);
                    setIsEditing(false);
                  }}
                  width={12}
                  height={3}
                  fontSize={16}
                  border={false}
                />
                <ActionButtonRounded
                  text="general.save"
                  onClick={() => {
                    updateTimeframe();
                    setTimeout(() => setIsEditing(false), 200);
                  }}
                  width={12}
                  height={3}
                  fontSize={16}
                  // TODO: Configure by type
                  disabled={Math.abs(calculatePartialDateDifferenceInMonths(partialStartDate, partialEndDate)) > 12}
                  disabledTooltip={<span>{fm('patientRecords.timeframeCanBeMaxOneYear')}</span>}
                />
              </div>
            </div>
          )}
        </div>
        {children}
      </Frame>
    );
  }
  return <React.Fragment>{children}</React.Fragment>;
};

// Collapsing text area for submitting additional information into texts
const CollapsingTextInputArea = ({
  contentText,
  onChangeText,
  sectionIndex,
  contentIndex,
  editing,
  headerText,
  infoText,
}: {
  contentText?: string | null;
  onChangeText: (text: string, sectionIndex: number, contentIndex: number) => void;
  sectionIndex: number;
  contentIndex: number;
  editing: boolean;
  headerText: string;
  infoText: string | null;
  type: string | null;
}) => {
  const [open, setOpen] = React.useState(!!(contentText && contentText !== ''));

  let textAreaElement: HTMLElement | null = null;
  const onInput = (thisElement: HTMLElement | null) => {
    if (!thisElement || !thisElement.style) return;
    thisElement.style.height = 'auto';
    thisElement.style.height = thisElement.scrollHeight + 'px';
  };

  React.useEffect(() => {
    textAreaElement = document.getElementById(sectionIndex + contentIndex + 'newInfo');
    if (textAreaElement) {
      textAreaElement.setAttribute('style', 'height:' + textAreaElement.scrollHeight + 'px;overflow-y:hidden;');
      textAreaElement.addEventListener('input', () => onInput(textAreaElement), false);
    }
    return () => {
      textAreaElement?.removeEventListener('input', () => onInput(textAreaElement));
    };
  }, [editing]);

  return editing ? (
    <TextInputArea>
      <div onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
        <Container>
          <Item style={{ width: '3rem' }}>
            {open ? <ArrowDropDown style={{ display: 'block' }} /> : <ArrowRight style={{ display: 'block' }} />}
          </Item>
          <Item xs={true}>{headerText}</Item>
          {infoText && <InfoPopper content={<>{infoText}</>} disableOnCloseProp />}
        </Container>
      </div>
      <Collapse in={open}>
        <div style={{ margin: '1rem 3rem 1rem 3rem' }}>
          <TextArea
            type="TextArea"
            name={sectionIndex + contentIndex + 'newInfo'}
            id={sectionIndex + contentIndex + 'newInfo'}
            value={contentText || undefined}
            onChange={(e: TOnChangeValues) => {
              const value = e[Object.keys(e)[0]] as string | undefined | null;
              if (contentText === value) return;
              if (value || value === '') onChangeText(value, sectionIndex, contentIndex);
            }}
            editing={true}
            placeholder={headerText}
            fullWidth={true}
          />
        </div>
      </Collapse>
    </TextInputArea>
  ) : !editing && contentText && contentText !== '' ? (
    <TextInputArea>
      <div style={{ whiteSpace: 'pre-wrap' /* Enable /n and leading white-spaces in text */ }}>{contentText}</div>
    </TextInputArea>
  ) : null;
};

/**
 * Make displayable component from clinical document contents and possible text changes
 */
const ClinicalDocElements = ({
  clinicalDocument,
  textContentNew,
  setTextContentNew,
  setTimeframeNew,
  fm,
  editing,
  template,
}: IClinicalDocElements): JSX.Element | null => {
  const content = clinicalDocument.content;

  const onChangeContentText = (text: string, sectionIndex: number, contentIndex: number) => {
    if (!textContentNew || !setTextContentNew) return;
    const filterPrevious = textContentNew.filter(
      (f) => !(f.sectionIndex === sectionIndex && f.contentIndex === contentIndex),
    );
    setTextContentNew([...filterPrevious, { sectionIndex, contentIndex, text }]);
  };

  const getContentText = (sectionIndex: number, contentIndex: number) =>
    (textContentNew || []).find((text) => text.sectionIndex === sectionIndex && text.contentIndex === contentIndex)
      ?.text;

  const templateSection = (docTitleCode: string) =>
    template.content.sections.find((sec) => sec.titleCode === docTitleCode);

  const templateTextContentInfoText = (docTitleCode: string, textContentIndex: number) =>
    templateSection(docTitleCode)?.textContent[textContentIndex]?.infoText;

  const templateTextContentType = (docTitleCode: string, textContentIndex: number) =>
    templateSection(docTitleCode)?.textContent[textContentIndex]?.type;

  return (
    <>
      {/* Patient status <Section>
        <Title>{content.titleText}</Title>
      </Section> */}
      {content.sections.map((section, sectionIndex) => {
        if (section.renderRule === 'not-empty' && section.textContent.every((c) => c === null))
          return <React.Fragment key={sectionIndex}></React.Fragment>;
        return (
          <Section key={section.titleText}>
            <Title>{section.titleText}</Title>
            {section.textContent.map((textContent, contentIndex) => {
              const timeframe = getTextContentDefaultTimeframeByType(
                clinicalDocument,
                textContent,
                templateTextContentType(section.titleCode, contentIndex),
              );
              return textContent ? (
                <ConditionalAreaWithTimeframe
                  key={contentIndex}
                  editing={editing}
                  condition={textContentTypesWithTimeframe.includes(
                    templateTextContentType(section.titleCode, contentIndex) ?? '',
                  )}
                  timeframe={timeframe}
                  setTimeframeNew={setTimeframeNew}
                  type={templateTextContentType(section.titleCode, contentIndex)}
                  currentTextContent={{
                    sectionIndex,
                    contentIndex,
                  }}
                >
                  {textContent?.editable ? (
                    <CollapsingTextInputArea
                      contentText={getContentText(sectionIndex, contentIndex) || textContent.text}
                      onChangeText={onChangeContentText}
                      sectionIndex={sectionIndex}
                      contentIndex={contentIndex}
                      editing={editing}
                      headerText={fm('patientRecords.enterMoreInformation')}
                      infoText={templateTextContentInfoText(section.titleCode, contentIndex) ?? null}
                      type={templateTextContentType(section.titleCode, contentIndex) ?? null}
                    />
                  ) : (
                    <Paragraph>{textContent?.text}</Paragraph>
                  )}
                </ConditionalAreaWithTimeframe>
              ) : null;
            })}
          </Section>
        );
      })}
    </>
  );
};

interface IClinicalDocElements {
  clinicalDocument: IArchiveObject;
  textContentNew?: IClinicalTextObject[];
  setTextContentNew?: (data: IClinicalTextObject[]) => void;
  timeframeNew?: IClinicalTextObject | undefined;
  setTimeframeNew?: (data: IClinicalTextObject | undefined) => void;
  fm: (id: string) => string;
  editing: boolean;
  template: INeuroArchiveTemplate;
}

export default ClinicalDocElements;
