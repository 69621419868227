import * as React from 'react';

import { Container, Item } from 'Components/Grid';

const Columns = ({ leftItem, rightItem }: IColumns) => {
  return (
    <Container>
      {leftItem && <Item style={{ width: '55%' }}>{leftItem}</Item>}
      {rightItem && <Item xs={true}>{rightItem}</Item>}
    </Container>
  );
};

interface IColumns {
  leftItem?: JSX.Element | Array<JSX.Element>;
  rightItem?: JSX.Element | Array<JSX.Element>;
}

export default Columns;
