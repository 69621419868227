import { TTEXT_CONTENT_BLOCK } from 'neuro-data-structures';
import { IArchiveObject } from './utils/helpers';

export const textContentTypesWithTimeframe = ['resmed-pap-device-treatment-response'];

export const getTextContentDefaultTimeframeByType = (
  clinicalDocument: IArchiveObject,
  textContent: TTEXT_CONTENT_BLOCK | null,
  type?: string,
): { dataStartTimestamp: number; dataEndTimestamp: number } | undefined => {
  switch (type) {
    case 'resmed-pap-device-treatment-response': {
      const dataStartTimestamp =
        textContent?.dataStartTimestamp ??
        (() => {
          const creationDate = new Date(textContent?.dataEndTimestamp ?? clinicalDocument.creationTimestamp);
          return new Date(creationDate.setFullYear(creationDate.getFullYear() - 1)).getTime();
        })();

      const dataEndTimestamp = textContent?.dataEndTimestamp ?? clinicalDocument.creationTimestamp;

      return { dataStartTimestamp, dataEndTimestamp };
    }
    default:
      return undefined;
  }
};

export const getTextContentTimeframeSelectorLocByType = (type?: string): { default: string; editing: string } => {
  switch (type) {
    case 'resmed-pap-device-treatment-response': {
      return {
        default: 'patientRecords.chosenResMedTreatmentResponseTimeframe',
        editing: 'patientRecords.chooseResMedTreatmentResponseTimeframe',
      };
    }
    default:
      return {
        default: 'patientRecords.chosenTimeframe',
        editing: 'patientRecords.chooseTimeframe',
      };
  }
};
