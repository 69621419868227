import { Popover, Slide } from '@mui/material';
import { styled } from '@mui/system';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import colors from '../../../config/theme/colors';

interface IMenuItemProps {
  preferred?: boolean;
}

const StyledMenuHeader = styled('div')({
  width: '25rem',
  maxWidth: '25rem',
  padding: '0.5rem 1.2rem',
  boxSizing: 'border-box',
  backgroundColor: colors.white,
  alignItems: 'center',
  fontSize: '1.2rem',
  '* > &:not(:first-of-type)': {
    borderTop: `1px solid ${colors.gray}`,
    paddingTop: '1.5rem',
  },
});

const StyledMenuItem = styled('div')({
  width: 'inherit',
  padding: '0.5rem 1.2rem',
  boxSizing: 'border-box',
  backgroundColor: colors.white,
  borderTop: `1px solid ${colors.gray}`,
  '&:hover': {
    backgroundColor: colors.lightGray,
  },
  cursor: 'pointer',
});

const HeaderText = styled('div')({
  fontSize: '1.2rem',
  lineHeight: '1.6rem',
  fontWeight: 600,
  color: colors.darkGray,
});

const PlatformText = styled('div', {
  shouldForwardProp: (prop) => prop !== 'preferred',
})(({ preferred }: IMenuItemProps) => ({
  fontSize: '1.6rem',
  lineHeight: '1.6rem',
  fontWeight: preferred ? 600 : 400,
  color: preferred ? colors.primaryText : colors.gray,
}));

const PlatformMenu = ({ platformList, preferred, onChangePlatform, anchorEle }: IOwnProps): JSX.Element => {
  const [inset, setInset] = React.useState<number>(3);

  const otherPlatforms = platformList.filter((p) => (preferred ? !preferred.includes(p) : p));

  React.useEffect(() => {
    if (!anchorEle) {
      setInset(3);
      return;
    } else {
      const timer = setTimeout(() => setInset(0), 225);
      return () => clearTimeout(timer);
    }
  }, [anchorEle]);

  return (
    <Popover
      open={!!anchorEle}
      anchorEl={anchorEle}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      TransitionComponent={Slide}
      style={{ zIndex: 10, clipPath: `inset(${inset}% 0 0 0)` }}
      slotProps={{
        paper: {
          style: {
            borderTopLeftRadius: '0',
            borderTopRightRadius: '0',
            borderBottomLeftRadius: '0.3rem',
            borderBottomRightRadius: '0.3rem',
          },
          elevation: 3,
          sx: { width: { xs: '21rem', md: '25rem' } }, // Adjust menu popover to size with platform selection button
        },
      }}
    >
      {preferred.length > 0 && (
        <>
          <StyledMenuHeader>
            <HeaderText>
              <FormattedMessage id={`header.headers.preferred`} />
            </HeaderText>
          </StyledMenuHeader>
          {preferred.map((p, i: number) => (
            <StyledMenuItem key={i} onClick={onChangePlatform(p)}>
              <PlatformText preferred>
                <FormattedMessage id={`header.platforms.${p}`} />
              </PlatformText>
            </StyledMenuItem>
          ))}
        </>
      )}

      {otherPlatforms.length > 0 && (
        <>
          <StyledMenuHeader>
            <HeaderText>
              <FormattedMessage id={`header.headers.other`} />
            </HeaderText>
          </StyledMenuHeader>

          {otherPlatforms.map((p, i: number) => (
            <StyledMenuItem key={i} onClick={onChangePlatform(p)}>
              <PlatformText>
                <FormattedMessage id={`header.platforms.${p}`} />
              </PlatformText>
            </StyledMenuItem>
          ))}
        </>
      )}
    </Popover>
  );
};

interface IOwnProps {
  platformList: Platform[];
  preferred: Platform[];
  onChangePlatform: (n: Platform) => () => void;
  anchorEle: SVGSVGElement | HTMLElement | null;
}

export default PlatformMenu;
