import * as React from 'react';
import { LocalizerCtx } from '../../containers/Localizer';

/**
 * Get a localized string. Fallbacks to the given localization store key.
 *
 * @param key Localization store key
 * @param localizations Localization store
 * @param locale Locale variant
 * @returns Localized value, or the given localization store key if no localized
 *          value was found in the store.
 *
 * @example
 * const localizerCtx = React.useContext(LocalizerCtx);
 * const localizedValue: string = localize("general.noData", localizerCtx.messages, localizerCtx.locale);
 */
export function localize(key: string, localizations: Record<Locale, Record<string, string>>, locale: Locale): string {
  return localizations[locale]?.[key] ?? key;
}

/**
 * Render localized value of a localization key given as child.
 *
 * @example
 * import L from 'Components/Localized';
 * return <L>{"general.noData"}</L>;
 */
const Localized = (props: { children: string }): React.JSX.Element => {
  return (
    <LocalizerCtx.Consumer>
      {(ctx): React.ReactNode => {
        if (ctx.locale) return localize(props.children, ctx.messages, ctx.locale);
        else return props.children;
      }}
    </LocalizerCtx.Consumer>
  );
};

export default Localized;
