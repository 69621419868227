import * as React from 'react';
import { Select, MenuItem } from '@mui/material';
import { ICompactVaultUser } from 'neuro-data-structures';
import { useIntl } from 'react-intl';

/**
 * Selected main and secondary author.
 */
export interface ISelectedAuthors {
  /** Always set. Should default to the user in JWT. */
  main: ICompactVaultUser['userId'];
  /** The user in JWT if main author is different. */
  secondary: ICompactVaultUser['userId'] | null;
}

interface IOwnProps {
  users: ICompactVaultUser[];
  selectedAuthors: ISelectedAuthors;
  setSelectedAuthors: (authors: ISelectedAuthors) => void;
  currentUser: ICompactVaultUser;
  disabled: boolean;
}

/**
 * A dropdown menu for selecting a main author of a clinical archive document.
 */
const AuthorSelector = (props: IOwnProps): JSX.Element => {
  const { selectedAuthors, setSelectedAuthors } = props;

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const handleChange = (e: any) => {
    const selectedMainAuthor = props.users.find((user) => user.userId === e.target.value);

    let selectedSecondaryAuthor = null;
    if (selectedMainAuthor !== undefined) {
      if (selectedMainAuthor.userId !== props.currentUser.userId) {
        selectedSecondaryAuthor = props.currentUser;
      }

      setSelectedAuthors({
        main: selectedMainAuthor.userId,
        secondary: selectedSecondaryAuthor?.userId || null,
      });
    }
  };

  return (
    <Select
      style={{ width: '23rem' }}
      value={selectedAuthors.main || '_'}
      onChange={handleChange}
      disabled={props.disabled}
    >
      <MenuItem value={'_'} disabled={true} style={{ fontStyle: 'italic' }}>
        {fm('patientRecords.chooseMainAuthor')}
      </MenuItem>

      {props.users.map((user) => (
        <MenuItem key={user.userId} value={user.userId}>
          <Author user={user} />
        </MenuItem>
      ))}
    </Select>
  );
};

/** A single user as fetched from Vault. */
const Author = ({ user }: { user: ICompactVaultUser }): JSX.Element => (
  <>
    {user.firstNames} {user.lastNames}
  </>
);

export default AuthorSelector;
