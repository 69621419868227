const sortPlatformsByLocale = (
  platforms: Platform[] | undefined,
  fm: (id: string) => string,
): Platform[] | undefined => {
  if (!platforms) return undefined;
  const platformsNonPreferredLocalizedSorted: Array<[Platform, string]> = platforms
    .map((p: Platform): [Platform, string] => {
      const localized = fm(`header.platforms.${p}`).toUpperCase();
      return [p, localized];
    })
    .sort((a, b) => {
      const x = a[1];
      const y = b[1];
      if (x < y) {
        return -1;
      }
      if (y < x) {
        return 1;
      }
      return 0;
    });

  const sortedPlats: Platform[] = platformsNonPreferredLocalizedSorted
    ?.map((p: [Platform, string]) => {
      p.pop();
      return p;
    })
    .flat() as Platform[];
  return sortedPlats;
};

export { sortPlatformsByLocale };
