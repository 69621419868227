import { RootState } from 'Store/index.js';
import * as React from 'react';
import { useSelector } from 'react-redux';

type SessionValidatorProps = {
  invalidated_state_view: React.ReactNode;
  children: React.ReactElement;
};

/**
 * Render the app if the session is still valid, or some given invalidated state
 * indicating view if not.
 */
const SessionValidator = (props: SessionValidatorProps): React.ReactNode => {
  const { invalidated_at_client_time } = useSelector((state: RootState) => state.session.status);
  const is_session_valid = !invalidated_at_client_time;

  if (is_session_valid) {
    return props.children;
  } else {
    return props.invalidated_state_view;
  }
};

export default SessionValidator;
