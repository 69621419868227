/**
 * Query string build for extended search
 *
 *
 * COMPARATOR = '<' | '>' | '==' | '!=' | '>=' | '<='
 * VALUE = '$' + STRING | '#' + NUMBER | '%' + PARTIALDATE | '/' + REGEX
 * IN = '€'
 *
 */

import { flatten, includes, intersperse, join } from 'ramda';
import { getPartialDateGivenMonthsAgo, isPartialDate, nowPartialDate } from 'neuro-utils';
import { a2String } from '../../../utility/string';
import { IExtendedSearch } from './ExtendedSearch';
import { TESSearchFieldNames } from '../components/SearchFields';
import store from 'Store/index';
import { getAtcCodesByPlatform } from 'Routes/Medication/Document/config';

const diagnoses = {
  // MS
  keo: ['G36.9', 'G37.9', 'G36.0'],
  radiologicallyIsolatedSyndrome: ['radiologicallyIsolatedSyndrome'],
  ms: ['G35'],
  nmosd: ['G36.0'],
  // Parkinson
  G20: ['G20'],
  'G90.3': ['G90.3'],
  'G23.2': ['G23.2'],
  'G23.3': ['G23.3'],
  'G23.1': ['G23.1'],
  'G25.9': ['G25.9'],
  'G31.0': ['G31.0'],
  'F02.8*G31.8': ['F02.8*G31.8'],
  'F00.2*G30.8': ['F00.2*G30.8'],
  'F02.0*G31.0': ['F02.0*G31.0'],
  G25: ['G25'],
  'G25.0': ['G25.0'],
  'G25.1#': ['G25.1#'],
  'G25.2': ['G25.2'],
  'G25.3#': ['G25.3#'],
  'G25.4#': ['G25.4#'],
  'G25.5': ['G25.5'],
  'G25.6#': ['G25.6#'],
  'G25.8': ['G25.8'],
  // S & R
  sleepApneaSuspicion: ['sleepApneaSuspicion'],
  respiratoryFailureSuspicion: ['respiratoryFailureSuspicion'],
  'G47.3': ['G47.3'],
  'J96.1': ['J96.1'],
  'J96.9': ['J96.9'],
  'G70.0': ['G70.0'],
  'G73.1': ['G73.1'],
  // SMA
  'G12.0': ['G12.0'],
  'G12.1': ['G12.1'],
  // DMD
  'G71.06': ['G71.06'],
  noCropping: [''],
};

const formatStringArray = (arr: Array<string>): string =>
  '[' +
  intersperse(
    ',',
    arr.map((d) => '$' + d),
  ).join('') +
  ']';

const formatRegExpArray = (arr: Array<string>): string =>
  '/[' +
  intersperse(
    ',',
    arr.map((d) => '/"^' + d + '"'),
  ).join('') +
  ']';

const partialDateToString = (p?: PartialDate): string | undefined => {
  return p
    ?.filter((n) => {
      return Number.isInteger(n);
    })
    .join(',');
};

const maxDateToNextYearOrMonth = (date: PartialDate): PartialDate => {
  // Add one more month or year to date so that it covers the selected month or year
  if (date.every((d) => d)) return date; // Nothing to do
  if (date[0] && date[1]) {
    // Add month
    if (date[1] === 12) return [date[0] + 1, 1, date[2]];
    return [date[0], date[1] + 1, date[2]];
  }
  return [date[0] + 1, date[1], date[2]]; // Add year
};

const getDiagnosisQueryString = (platform?: Platform, includedDiagnoses?: string[] | 'noCropping') => {
  let query = '';
  if (Array.isArray(includedDiagnoses) && includedDiagnoses.length > 0) {
    // If search is limited to a specific diagnosis or a set of diagnoses
    query += formatStringArray(
      flatten(
        Array.isArray(includedDiagnoses)
          ? includedDiagnoses.map((d: string) => diagnoses[d as keyof typeof diagnoses])
          : [],
      ),
    );
  } else {
    // Include all diagnoses for the platform
    if (platform === 'ms') query += '[$G35,$G36.0,$G36.9,$G37.9,$radiologicallyIsolatedSyndrome]';
    if (platform === 'parkinson')
      query +=
        '[$G20,$G23.1,$G23.2,$G23.3,$G90.3,$G25.9,$G31.0,$F00.2*G30.8,$F02.8*G31.8,$F02.0*G31.0,$G25,$G25.0,$G25.1#,$G25.2,$G25.3#,$G25.4#,$G25.5,$G25.6#,$G25.8]';
    if (platform === 'sma') query += '[$G12.0,$G12.1]';
    if (platform === 'dmd') query += '[$G71.06]';
    if (platform === 'huntington') query += '[$G10,$Z82]';
    if (platform === 'epilepsy')
      query +=
        '[$G40,$G40.0,$G40.00,$G40.01,$G40.09,$G40.1,$G40.10,$G40.11,$G40.12,$G40.19,$G40.2,$G40.20,$G40.21,$G40.22,$G40.29,$G40.3,$G40.30,$G40.31,$G40.33,$G40.34,$G40.35,$G40.36,$G40.37,$G40.39,$G40.4,$G40.5,$G40.50,$G40.51,$G40.52#,$G40.59,$G40.6,$G40.7,$G40.80,$G40.89,$G40.9,$G41,$G41.0,$G41.1,$G41.2,$G41.8,$G41.9]';
    if (platform === 'sleepApnea') query += '[$sleepApneaSuspicion,$respiratoryFailureSuspicion,$G47.3,$J96.1,$J96.9]';
    if (platform === 'mgravis') query += '[$G70.0,$G73.1]';
  }
  return query;
};

const generateAdvancedSearchString = (
  fields: { [key: string]: TESSearchFieldNames[] },
  state: IExtendedSearch['searchFields'] & { platform: IExtendedSearch['platform'] },
): string | undefined => {
  const medicationSettings = store.getState().settings?.orgSettings?.settings?.medicationSettings;

  const searchStringFields: string[] = [];

  const diseaseModifyingDrugs =
    typeof state.platform === 'string' &&
    state.platform.length > 0 &&
    medicationSettings &&
    Object.keys(medicationSettings).includes(state.platform)
      ? '$' + formatStringArray(getAtcCodesByPlatform(medicationSettings, state.platform))
      : '$[]';

  const specificMedication = (medication: string[]) => '$' + formatStringArray(medication);

  const specificAtc = (atc: string[]) => formatRegExpArray(atc);

  Object.keys(fields).forEach((docType) => {
    const thisDocumentFields: string[] = [];
    // Filter fields that have been not been filled
    const thisFields = fields[docType].filter(
      (field) => !['diagnosis', 'diagnosisDate'].includes(field) && state[field] && state[field] !== 'noCropping',
    );

    // This is for fields that don't use functions in query
    thisFields.forEach((f) => {
      let query: string | undefined = undefined;
      switch (f) {
        /**
         * Common
         */

        case 'age':
          break;

        case 'gender':
          break;

        case 'includeDeceased': {
          break;
        }

        /**
         * Batch handle:
         * - platform
         * - diagnosis
         * - diagnosisDate
         */
        case 'platform': {
          query = '';
          const dg = state.diagnosis;
          const dgDate = state.diagnosisDate;
          if (state.platform === 'ninmt') {
            query += 'ninmtTreatmentPeriod@date!=NULL';
            break;
          }

          // Start of the diagnosis query string and list of diagnoses
          query += 'diagnosis@_€{data.diagnosis€$';
          query += getDiagnosisQueryString(state.platform, dg);

          if (dgDate !== 'noCropping') {
            // diagnosisDate
            query += '&';
            if (isPartialDate(dgDate.diagnosisMinDate)) {
              query += `data.date>=%${partialDateToString(dgDate.diagnosisMinDate)}`;
              if (isPartialDate(dgDate.diagnosisMaxDate)) query += `&`;
            }
            if (isPartialDate(dgDate.diagnosisMaxDate))
              query += `data.date<=%${partialDateToString(maxDateToNextYearOrMonth(dgDate.diagnosisMaxDate))}`;
          }
          query += '}';
          break;
        }
        case 'typeOfDisease': // MS
          query = '';
          if (
            state.typeOfDisease !== 'noCropping' &&
            Array.isArray(state.typeOfDisease) &&
            state.typeOfDisease.length > 0
          ) {
            query += `${docType}@${f}.0.type€${'$' + formatStringArray(state.typeOfDisease)}`;
          }
          break;

        case 'patientAgeOnDg': // MS, Get patient whose age was within the given numbers when received selected diagnosis (dg date)
          query = '';
          if (state.patientAgeOnDg !== 'noCropping' && Array.isArray(state.patientAgeOnDg)) {
            query +=
              `§patientAgeOnDg${getDiagnosisQueryString(state.platform, state.diagnosis)}` +
              `€{data>=#${state.patientAgeOnDg[0]}&data<=#${state.patientAgeOnDg[1]}}`;
          }
          break;

        case 'medication': {
          query = '';
          if (state.medication !== 'noCropping' && Array.isArray(state.medication) && state.medication.length > 0) {
            // First filter empty medication criteria
            const filteredMedications = state.medication.filter((med) => med.medication);
            filteredMedications.forEach((m, i, arr) => {
              const noMedicationQuery = (): string => {
                let q = '';
                // There are no medication documents
                q += `§countDocsInDateRange[$medication,NULL,%"${partialDateToString(
                  nowPartialDate(),
                )}",$startDate]==#0`;
                // OR medication documents do not match criteria
                if (m.status === 'inUse')
                  q += `|medication@_€{data.atc!€${diseaseModifyingDrugs}|data.hasEnded€{exists==TRUE&data.0==TRUE}}`;
                if (m.status === 'earlierInUse')
                  q += `|medication@_€{data.atc!€${diseaseModifyingDrugs}|data.hasEnded€{exists==FALSE|data.0==FALSE}}`;
                return q;
              };
              query += '(';
              if (['anyMedication', 'specificMedication', 'specificAtc'].includes(m.medication!)) {
                if (m.medication === 'specificMedication' && Array.isArray(m.specificMedication)) {
                  // status === 'notInUse'
                  if (m.status === 'notInUse') query += '!';
                  // There are (no) medication documents with given medication name(s)
                  query += `medication@_€{data.medicationName€${specificMedication(m.specificMedication)}`;
                } else if (m.medication === 'specificAtc' && Array.isArray(m.specificAtc)) {
                  // There are medication documents with given ATC code(s)
                  query += `medication@_€{data.atc€${specificAtc(m.specificAtc)}`;
                } else {
                  if (m.medication === 'anyMedication' || state.platform === 'ms') {
                    // There are medication documents with disease modifying drugs
                    query += `medication@_€{data.atc€${diseaseModifyingDrugs}`;
                  } else if (m.status === 'notInUse') {
                    // There are no medication documents
                    query += noMedicationQuery();
                  } else {
                    // There are medication documents
                    query += 'medication@_€{data.startDate!=NULL';
                  }
                }
                // status === 'notInUse' - handled above
                // status === 'inUse'
                if (m.status === 'inUse') query += '&data.hasEnded€{exists==FALSE|data.0==FALSE}';
                // status === 'earlierInUse'
                if (m.status === 'earlierInUse') query += '&data.hasEnded€{exists==TRUE&data.0==TRUE}';
                query += '}';
              }
              if (m.medication === 'noMedication') {
                query += noMedicationQuery();
              }
              query += `)${arr[i + 1] && arr[i + 1].medication ? '&' : ''}`;
              if (i === arr.length - 1) query = `(${query})`;
            });
          }
          break;
        }

        /**
         * MS
         */

        case 'relapseDuringLast24Months': {
          query = '';
          if (state.relapseDuringLast24Months !== 'noCropping') {
            if (state.relapseDuringLast24Months === 'yes') {
              query += `relapse@startDate>=%${partialDateToString(getPartialDateGivenMonthsAgo(24))}`;
            }
            if (state.relapseDuringLast24Months === 'no') {
              query += `!relapse@startDate>=%${partialDateToString(getPartialDateGivenMonthsAgo(24))}`;
            }
          }
          break;
        }

        case 'relapseDuringLast12Months':
          query = '';
          if (state.relapseDuringLast12Months !== 'noCropping') {
            if (state.relapseDuringLast12Months === 'yes') {
              query += `relapse@startDate>=%${partialDateToString(getPartialDateGivenMonthsAgo(12))}`;
            }
            if (state.relapseDuringLast12Months === 'no') {
              query += `!relapse@startDate>=%${partialDateToString(getPartialDateGivenMonthsAgo(12))}`;
            }
          }
          break;

        case 'numberOfRelapses':
          query = '';
          if (state.numberOfRelapses !== 'noCropping') {
            const d = getPartialDateGivenMonthsAgo(12);
            if (state.numberOfRelapses === 'more') {
              query += `§countDocsInDateRange[$${docType},%"${partialDateToString(d)}",NULL,$startDate]>#3`;
            } else {
              query += `§countDocsInDateRange[$${docType},%"${partialDateToString(d)}",NULL,$startDate]==#${
                state.numberOfRelapses
              }`;
            }
          }
          break;

        case 'edss':
          if (state.edss !== 'noCropping' && state.edss.length > 0) {
            query = '(';
            query += `§getLatestDocumentFieldValue[$${docType},$calculatedEdssStep,$date]€#[${join(
              ',',
              state.edss.map((e) => '#' + e.toString()),
            ).toString()}]`;
            query += `|§getLatestDocumentFieldValue[$${docType},$edssStep,$date]€#[${join(
              ',',
              state.edss.map((e) => '#' + e.toString()),
            ).toString()}])`;
          }
          break;

        case 'edssIncreasedDuringLast24Months':
        case 'edssIncreasedDuringLast12Months': {
          const months = f === 'edssIncreasedDuringLast24Months' ? 24 : 12;
          const s = months === 24 ? state.edssIncreasedDuringLast24Months : state.edssIncreasedDuringLast12Months;
          if (s !== 'noCropping') {
            if (s === 'yes') {
              query = '(';
              query += `§getLatestDocumentFieldValue[$${docType},$date,$date]>=%${partialDateToString(
                getPartialDateGivenMonthsAgo(months),
              )}`;
              query += `&(§calculateLatestFieldValueTrend[$${docType},$calculatedEdssStep,$calculatedEdssStep,$date]>#0`;
              query += `|§calculateLatestFieldValueTrend[$${docType},$calculatedEdssStep,$edssStep,$date]>#0`;
              query += `|§calculateLatestFieldValueTrend[$${docType},$edssStep,$calculatedEdssStep,$date]>#0`;
              query += `|§calculateLatestFieldValueTrend[$${docType},$edssStep,$edssStep,$date]>#0)`;
              query += ')';
            }
            if (s === 'no') {
              query = '(';
              query += `§getLatestDocumentFieldValue[$${docType},$date,$date]<%${partialDateToString(
                getPartialDateGivenMonthsAgo(months),
              )}`;
              query += `|(§calculateLatestFieldValueTrend[$${docType},$calculatedEdssStep,$calculatedEdssStep,$date]<=#0`;
              query += `&§calculateLatestFieldValueTrend[$${docType},$calculatedEdssStep,$edssStep,$date]<=#0`;
              query += `&§calculateLatestFieldValueTrend[$${docType},$edssStep,$calculatedEdssStep,$date]<=#0`;
              query += `&§calculateLatestFieldValueTrend[$${docType},$edssStep,$edssStep,$date]<=#0)`;
              query += ')';
            }
          }
          break;
        }

        case 'spmsCriteria':
          query = '';
          if (state.spmsCriteria !== 'noCropping') {
            if (includes('expand', state.spmsCriteria)) query += '§spmsModifiedExpand!=NULL';
            if (includes('expand', state.spmsCriteria) && includes('lorscheider', state.spmsCriteria)) query += '|';
            if (includes('lorscheider', state.spmsCriteria)) query += '§spmsLorscheider!=NULL';
          }
          break;

        /**
         * Parkinson
         */

        case 'currentLEDD': {
          query = '';
          if (state.currentLEDD === 'noCropping') break;

          const qps: string[] = [];

          if (state.currentLEDD.minLEDD || state.currentLEDD.minLEDD === 0) {
            qps.push(`data>=#${state.currentLEDD.minLEDD}`);
          }

          if (state.currentLEDD.maxLEDD) {
            qps.push(`data<=#${state.currentLEDD.maxLEDD}`);
          }

          if (qps.length > 0) query += `§calculatePatientLedd€{${qps.join('&')}}`;
          break;
        }

        case 'dbsTreatment':
          query = '';
          if (state.dbsTreatment !== 'noCropping' && Array.isArray(state.dbsTreatment)) {
            query += 'dbs@hasEnded€{';
            if (state.dbsTreatment.includes('ongoing')) {
              query += '(exists==FALSE|data.0==FALSE|data.length==#0)';
              if (state.dbsTreatment.includes('ended')) query += '|';
            }
            if (state.dbsTreatment.includes('ended')) query += '(exists==TRUE&data.0==TRUE)';
            query += '}';
          }
          break;

        case 'currentHoehnYahr':
          query = '';
          if (state.currentHoehnYahr !== 'noCropping' && state.currentHoehnYahr.length > 0) {
            query += `§getLatestDocumentFieldValue[$${docType},$value,$date]€#[${join(
              ',',
              state.currentHoehnYahr.map((e) => '#' + e.toString()),
            ).toString()}])`;
          }
          break;

        case 'thalamotomyOrHIFU':
          query = '';
          if (state.thalamotomyOrHIFU !== 'noCropping' && Array.isArray(state.thalamotomyOrHIFU)) {
            if (state.thalamotomyOrHIFU.includes('thalamotomyDone')) {
              query += '(';
              query += 'thalamotomy@date!=NULL';
              if (state.thalamotomyOrHIFU.includes('hifuDone')) query += ')|';
            }
            if (state.thalamotomyOrHIFU.includes('hifuDone')) {
              query += '(';
              query += 'hifu@date!=NULL';
            }
            query += ')';
          }
          break;

        /**
         * S & R
         */

        case 'professionalDriving':
          query = 'background@professionalDriving==$yes';
          break;

        case 'treatment':
          query = '';
          if (state.treatment !== 'noCropping') {
            state.treatment.forEach((t, i, arr) => {
              // Ignore empty search criteria
              if (!Array.isArray(t.treatment)) return;
              query += '(';
              t.treatment.forEach((treatment, i, arr) => {
                query += '(';
                const treatmentType = treatment === 'postureTreatment' ? 'otherTreatment' : treatment;
                // Start date is earlier than / same as current date
                query += `${treatmentType}@_€{data.date<=%${partialDateToString(nowPartialDate())}`;
                // AND otherTreatmentType is postureTreatment IF treatmentType is otherTreatment
                if (treatmentType === 'otherTreatment') {
                  query += `&data.otherTreatmentType==$postureTreatment`;
                }
                if (t.status === 'ongoing') {
                  // AND treatment has not ended
                  query += `&data.hasEnded€{exists==FALSE|data==NULL|data.0==FALSE|data.length==#0}`;
                  // AND end date does not exist OR it is later than current date
                  query += `&data.endDate€{exists==FALSE|data==NULL|data>%${partialDateToString(nowPartialDate())}}`;
                }
                if (t.status === 'ended') {
                  // AND treatment has ended IF its type is not patientDoesNotWantRespiratorySupportTherapy
                  if (treatmentType !== 'patientDoesNotWantRespiratorySupportTherapy') {
                    query += `&data.hasEnded€{exists==TRUE&data.0==TRUE}`;
                  }
                  // AND end date exists AND it is earlier than current date
                  query += `&data.endDate€{exists==TRUE&data<%${partialDateToString(nowPartialDate())}}`;
                }
                query += '}';
                query += `)${i < arr.length - 1 ? '|' : ''}`;
              });
              query += `)${
                arr[i + 1] && Array.isArray(arr[i + 1].treatment) && (arr[i + 1].treatment?.length as number) > 0
                  ? '&'
                  : ''
              }`;
              if (i === arr.length - 1) query = `(${query})`;
            });
          }
          break;

        case 'latestBMI':
          query = '';
          if (state.latestBMI !== 'noCropping' && (state.latestBMI.minBMI || state.latestBMI.maxBMI)) {
            query += '§calculateLatestBMI€{';
            if (state.latestBMI.minBMI || state.latestBMI.minBMI === 0) {
              query += `data!=NULL&data>=#${state.latestBMI.minBMI}`;
              if (state.latestBMI.maxBMI) query += '&';
            }
            if (state.latestBMI.maxBMI) query += `data<=#${state.latestBMI.maxBMI}`;
            query += '}';
          }
          break;

        /**
         * Default
         */

        default:
          // Default docType@field==value
          query = `${docType}@${f}==$${state[f]}`;
      }
      query && thisDocumentFields.push(query);
    });
    a2String(intersperse('&', thisDocumentFields)) &&
      searchStringFields.push(a2String(intersperse('&', thisDocumentFields)));
  });
  return searchStringFields.length > 0 ? a2String(intersperse('&', searchStringFields)) : undefined;
};

export default generateAdvancedSearchString;
