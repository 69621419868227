import { styled, Zoom } from '@mui/material';
import * as React from 'react';
import { useIntl } from 'react-intl';

import Icon from 'Components/_NewElements/Icon';
import CircularProgressLocal from 'Components/CircularProgress';
import ToolTip from 'Components/ToolTip';
import { ICapabilityContextProps, withCapabilities } from 'Containers/CapabilityHandler';

import { assertCapabilities, useAppDispatch, useAppSelector } from 'Store/index';
import { actions } from 'Store/session';
import { syncCapabilities } from '../../../config/capabilities';
import { TooltipProps } from '@mui/material';

const Spinner = styled(CircularProgressLocal)(({ theme }) => ({
  padding: '0.2rem',
  color: theme.palette.primary.light,
}));

const SyncStatus = ({ iconSize = 2, tooltipPlacement, capabilityGroups = {} }: ISyncStatus) => {
  const dispatch = useAppDispatch();
  const syncStatusesObject = useAppSelector((s) => s.session.syncLoadStatus);
  const syncStatusesArray = Object.keys(syncStatusesObject || {}).map((name) => ({
    name,
    ...(syncStatusesObject || {})[name],
  }));

  const loading = syncStatusesArray.some((s) => s.status === 'loading');
  const error = syncStatusesArray.some((s) => s.status === 'error');

  const { formatMessage } = useIntl();
  const fm = (id: string) =>
    formatMessage(
      {
        id,
      },
      {
        br: <br />,
      },
    );

  const [status, setStatus] = React.useState<'loading' | 'done' | 'error' | null>(null);

  const timerId = React.useRef<ReturnType<typeof setTimeout>>();
  React.useEffect(() => {
    clearTimeout(timerId.current);
    if (loading && status !== 'loading') {
      setStatus('loading');
    }
    if (!loading && status === 'loading') {
      if (error) {
        setStatus('error');
        dispatch(
          actions.setExtraSnack({
            id: 'syncwarning',
            severity: 'warning',
            content: {
              templateName: 'syncwarning',
              title: fm('header.updateIntegration'),
              secondaryTitle: fm('header.updateFailed'),
              failedSyncs:
                error &&
                syncStatusesArray
                  .filter((sync) => sync.status === 'error')
                  .map((s) => fm('header.syncDocumentTypes.' + s.name)),
            },

            popup: true,
            rule: true,
          }),
        );
      } else {
        setStatus('done');
      }

      timerId.current = setTimeout(() => {
        setStatus(null);
      }, 6000);
    }
    return () => clearTimeout(timerId.current);
  }, [loading]);

  const [animeIn, setAnimeIn] = React.useState<boolean>(true);
  React.useEffect(() => {
    setAnimeIn(false);
    setTimeout(() => {
      setAnimeIn(true);
    }, 100);
  }, [status]);

  const syncFunction = () => (!loading ? actions.doSyncFetchesAndGetDocuments(dispatch, capabilityGroups) : undefined);

  const hasSyncCapabilities = syncCapabilities.some((sc) => assertCapabilities([sc], capabilityGroups));

  const containerRef = React.useRef<HTMLElement>(null);
  const animeTimeouts = { enter: 600, exit: 200 };
  return hasSyncCapabilities ? (
    <span
      ref={containerRef}
      onClick={syncFunction}
      style={{ height: iconSize + 'rem', cursor: loading ? 'default' : 'pointer' }}
    >
      <ToolTip
        placement={tooltipPlacement}
        title={
          <>
            <div style={{ marginBottom: '1rem' }}>{fm('header.updateIntegration')}</div>
          </>
        }
        description={
          <>
            {status === 'error' || (error && status !== 'loading')
              ? fm('header.updateFailed')
              : status === 'done'
                ? fm('header.updateSuccess')
                : status === 'loading'
                  ? fm('header.updateLoading')
                  : null}
            <ul>
              {error &&
                status !== 'loading' &&
                syncStatusesArray
                  .filter((sync) => sync.status === 'error')
                  .map((s) => (
                    <li key={s.name} style={{ fontWeight: 600 }}>
                      {fm('header.syncDocumentTypes.' + s.name)}
                    </li>
                  ))}
            </ul>
          </>
        }
        hover
        content={
          <Zoom in={animeIn} timeout={animeTimeouts}>
            <div>
              {status === 'loading' ? (
                <Spinner size={iconSize * 10 - 4} />
              ) : status === 'error' ? (
                <Icon size={iconSize} icon="cancel" color="errorLight" button />
              ) : status === 'done' ? (
                <Icon size={iconSize} icon="success" color="success" button />
              ) : (
                <Icon size={iconSize} icon="sync" color={error ? 'errorLight' : 'appBlueLight'} button />
              )}
            </div>
          </Zoom>
        }
      />
    </span>
  ) : null;
};

interface ISyncStatus extends ICapabilityContextProps {
  iconSize?: number;
  tooltipPlacement?: TooltipProps['placement'];
}

export default withCapabilities(SyncStatus);
